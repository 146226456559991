import { isNil, omitBy } from 'lodash';
import { GetServerSideProps } from 'next';
import React from 'react';
import HubContainer from '../advisorHub/components/HubContainer';
import { knownLanguages, knownStoryContexts } from '../advisorHub/config';
import { mapBrandSetupRes } from '../advisorHub/utils/mapper';
import {
  allBrands,
  alsBrandId,
  chopardBrandId,
  inspifyBrandId,
  iwcBrandId,
  jlcBrandId,
  piagetBrandId,
  rduBrandId,
  vacBrandId,
  zegnaBrandId,
  cartierBrandId,
  aspreyBrandId,
  vanCleefArpelsBrandId,
  tiffanyBrandId,
  gustoLuxeBrandId,
  inspifyTestBrandId,
  allBoutiqueUrl
} from '../config';
import {
  IBrandSetup,
  IBrandSetupResponse,
  IHubSearchFilters,
  ProductFamily,
  ProductSearchFilter
} from '../interfaces';
import {
  getProductFamilies,
  getProductSearchFilters
} from '../services/products';
import logger from '../services/serverLogger';
import { getBrandByUrl } from '../services/stores';
import { setIsOnboardingBrand } from '../utils/window';
import Error from './_error';

const injectBrand = (brandSetup: IBrandSetup) => {
  if (brandSetup) {
    if (!allBrands.find((b) => b.id === brandSetup.id)) {
      allBrands.push({
        id: brandSetup.id,
        name: brandSetup.brandName,
        host: brandSetup.brandUrl,
        isNotPreConfigured: true
      });
    }
  }
};

const AdvisorHub = ({
  brandId,
  searchFilters,
  brandSetup
}: {
  brandId: string;
  searchFilters: IHubSearchFilters;
  brandSetup?: IBrandSetup;
}) => {

  React.useEffect(() => {
    if (brandSetup) {
      setIsOnboardingBrand(true);
      injectBrand(brandSetup)
      allBoutiqueUrl[brandSetup.id] = {
        salesLoft: brandSetup.brandUrl
      };
      return
    } 
   setIsOnboardingBrand(false);
  }, [brandSetup]);
  
  if (!brandId) return <Error hasGetInitialPropsRun={true} err={null} />;

  return (
    <HubContainer
      brandId={brandId}
      searchFilters={searchFilters}
      brandSetup={brandSetup}
    />
  );
};

export const brandIds = {
  'jaeger-lecoultre': jlcBrandId,
  piaget: piagetBrandId,
  iwc: iwcBrandId,
  'alange-soehne': alsBrandId,
  rogerdubuis: rduBrandId,
  zegna: zegnaBrandId,
  chopard: chopardBrandId,
  inspify: inspifyBrandId,
  'inspify-dev': inspifyBrandId,
  'inspify-test': inspifyTestBrandId,
  meet: inspifyBrandId,
  'vacheron-constantin': vacBrandId,
  cartier: cartierBrandId,
  asprey: aspreyBrandId,
  vancleefarpels: vanCleefArpelsBrandId,
  tiffany: tiffanyBrandId,
  'gusto-luxe': gustoLuxeBrandId,
  'meet-test': inspifyTestBrandId
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const host = context.req.headers.host;
  const brandName = host.split('.')[0];
  let brandId = brandIds[brandName];
  let productFamilies: ProductFamily[] = [];
  let productSearchFilters: ProductSearchFilter[] = [];
  let brandSetup: IBrandSetupResponse | null = null;

  // not preconfigured brand
  if (!brandId) {
    brandSetup = await getBrandByUrl(host).catch((e) => {
      logger.error(e, `Error when getting branding setup: brand not found, brandURL=${host}`);
      return null;
    });

    if (brandSetup) {
      brandSetup.brand_url = host;
      brandId = brandSetup.id;
    }
  }

  if (host === 'app.inspify.com') {
    return {
      redirect: {
        destination: 'https://meet.inspify.com/hub',
        permanent: false
      }
    };
  }

  [productFamilies, productSearchFilters] = await Promise.all([
    getProductFamilies(brandId).catch((e) => {
      const key = `iris/${brandId}/productFamilies/families.json`
      logger.error(e, `Error when getting product families: no such key, key=${key}`);
      return [];
    }),
    getProductSearchFilters(brandId).catch((e) => {
      const key = `iris/${brandId}/search/productSearchFilters.json`
      logger.error(e, `Error when getting product search filters: no such key, key=${key}`);
      return [];
    })
  ]);

  return {
    props: {
      brandId: brandId,
      brandSetup: brandSetup
        ? omitBy(mapBrandSetupRes(brandSetup), isNil)
        : null,
      searchFilters: {
        productFamilies,
        productSearchFilters,
        storyContext: knownStoryContexts,
        storyLanguages: knownLanguages
      }
    }
  };
};

export default AdvisorHub;
